export type TripPayload = {
  start_date: string;
  end_date: string;
  mode_of_travel: string;
  departure_terminal: string;
  country: string;
  trip_name: string;
  country_entry: string;
  country_exit: string;
  country_destination: string;
  departure_dt: string;
  vessel_number: number;
  approxTime: string;
};

interface TransactionReceipt {
  status: string;
}

export type RevenirTransactionType = {
  receipt: TransactionReceipt;
  account_number: string;
  archived: boolean;
  country: string;
  merchant_name: string;
  transaction_category: string;
  transaction_date: string;
  total_transaction_reclaim: string;
  total_amount: number;
  receipt_status: string;
  total_vat_amount: number;
  transaction_id: string;
  iconStatus: string;
  isError: boolean;

  currency: string; // requested
  receipt_failed_reason: string; // requested
  vat_reclaim_status: string; // requested
};

export type RevenirTransactionItemType = {
  item_description: string;
  category: string;
  eligible: boolean;
  quantity: number;
  discount: number;
  tax: number;
  vat_rate: number;
  price: number;
  total: number;
  vat_amount: string;
  currency: string; // requested
};

export type TripDetailsType = {
  archived: boolean;
  is_boarding_pass_uploaded: boolean;
  country_entry: string;
  country_exit: string;
  departure_terminal: string;
  country_destination: string;
  mode_of_travel: string;
  total_reclaim: number;
  barcode_status: string;
  trip_start_date: string;
  trip_end_date: string;
  transactions: RevenirTransactionType[];
};

export type RevenirTripType = {
  archived: boolean;
  is_boarding_pass_uploaded: boolean;
  trip_id: string;
  trip_name: string;
  start_date: string;
  end_date: string;
  country: string;
  trip_status: string;
  url?: string;
};
export type TripsList = {
  trips: RevenirTripType[];
};

export function imageUrlFromImageName(imagePath: string, imageName: string): string {
  const lowerCaseImageName = revenirCountryImage(imageName);

  if (lowerCaseImageName) {
    return `/assets/${imagePath}/${lowerCaseImageName}.jpeg`;
  } else {
    return `/assets/${imagePath}/no-country-image.svg`;
  }
}

function revenirCountryImage(country: string) {
  const countryImageKey = [
    { country: 'FRA', imgUrl: 'France' },
    { country: 'BEL', imgUrl: 'Belgium' },
    { country: 'ITA', imgUrl: 'Italy' },
    { country: 'NLD', imgUrl: 'Netherlands' },
    { country: 'PRT', imgUrl: 'Portugal' },
    { country: 'SPA', imgUrl: 'Spain' },
  ];
  return countryImageKey.find((x) => x.country == country)?.imgUrl;
}
export interface DropdownOption {
  id: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
