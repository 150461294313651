import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  AdHocFeeRequest,
  BaseWidgetProperties,
  ClientDetailsType,
  FeeItem,
  FeeUnitChargeTypeEnum,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import { ValidationFeeDetails } from '../../../actions/fee-management.action';
import { ResetFormDataAction, UpdateFormDataActionWithId } from '../../../actions/form-submission.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FeeManagementState } from '../../../state/fee-management.state';
import { FormActionState } from '../../../state/form-submision.state';
import { removeNullUndefinedValuesFromObject } from '../../utils';
import { redirectToPage } from '../cta-button-actions.utils';

export function handleAdHocFeeSubmission(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  __: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  feeManagementService: FeeManagementService,
) {
  const formDataWithId = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_EDIT_FEE),
  )?.formData;
  const formData = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData;
  const clientDetails = store.selectSnapshot(FeeManagementState.getFeeManagement)?.selectedClientDetails;
  const selectedFeeDetails = store.selectSnapshot(FeeManagementState.getFeeManagement)?.selectedFeeData;
  if (formDataWithId && clientDetails && formData) {
    if (formDataWithId?.isCurrencyFeeFormValid) {
      const feeChargeValue =
        formDataWithId.feeDetails.unitCharge.value === FeeUnitChargeTypeEnum.FIXED_FEE
          ? formDataWithId.feeDetails?.feeAmount
          : formDataWithId.feeDetails?.feePercentage;
      let payload = createPayload(
        feeChargeValue,
        clientDetails,
        formData,
        formDataWithId,
        selectedFeeDetails,
      );
      payload = removeNullUndefinedValuesFromObject<AdHocFeeRequest>(payload);
      if (selectedFeeDetails?.id) {
        submitEditRequest(
          feeManagementService,
          store,
          router,
          alertHandlerService,
          widgetProperties,
          payload,
          selectedFeeDetails.id,
        );
      } else {
        submitCreateRequest(
          feeManagementService,
          store,
          router,
          alertHandlerService,
          widgetProperties,
          payload,
        );
      }
    } else {
      showAlertAndFormErrors(store, alertHandlerService);
    }
  } else {
    showAlertAndFormErrors(store, alertHandlerService);
  }
}

function createPayload(
  feeChargeValue: number,
  clientDetails: ClientDetailsType,
  formData: any,
  formDataWithId: any,
  selectedFeeDetails?: FeeItem,
): AdHocFeeRequest {
  return {
    name: formDataWithId.feeDetails?.feeName,
    currency: formData?.currency ?? selectedFeeDetails?.currency,
    clientId: clientDetails.id,
    accountId: formData?.accountId ?? selectedFeeDetails?.adHocFee?.accountId,
    unitChargeType: formDataWithId.feeDetails?.unitCharge.value,
    unitChargeInfo: {
      feeCharge: feeChargeValue,
      minFeeAmount: formDataWithId.feeDetails?.minimumFeeAmount,
      maxFeeAmount: formDataWithId.feeDetails?.maximumFeeAmount,
    },
    frequencyType: formDataWithId.scheduleDetails?.frequency.id,
    frequencyInfo: {
      startDate: formDataWithId.scheduleDetails?.effectiveFrom,
    },
  };
}

function submitEditRequest(
  feeManagementService: FeeManagementService,
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  widgetProperties: BaseWidgetProperties,
  payload: AdHocFeeRequest,
  id: string,
) {
  feeManagementService
    .submitEditFeeRequest(payload, id)
    .pipe(
      take(1),
      tap((res) => {
        if (res) {
          alertHandlerService.showAlertFn('success', 'Fee updated successfully');
          store.dispatch(new ResetFormDataAction());
          store.dispatch(new ValidationFeeDetails(res));
          redirectToPage(router, widgetProperties);
        }
      }),
    )
    .subscribe();
}

function submitCreateRequest(
  feeManagementService: FeeManagementService,
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  widgetProperties: BaseWidgetProperties,
  payload: AdHocFeeRequest,
) {
  feeManagementService
    .createAdHocFeeFeeRequest(payload)
    .pipe(
      take(1),
      tap((res) => {
        if (res) {
          alertHandlerService.showAlertFn('success', 'Fee created successfully');
          store.dispatch(new ResetFormDataAction());
          store.dispatch(new ValidationFeeDetails(res));
          redirectToPage(router, widgetProperties);
        }
      }),
    )
    .subscribe();
}

function showAlertAndFormErrors(store: Store, alertHandlerService: AlertHandlerService) {
  alertHandlerService.showAlertFn('error', 'Please provide all details in the form before proceeding');
  store.dispatch(new UpdateFormDataActionWithId({ showFormErrors: true }, FormActionTypeEnum.ADD_EDIT_FEE));
}
