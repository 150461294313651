<div class="alert-wrapper" [ngStyle]="erroralertMsgdiv">
  <div class="alert-message">
    <mat-icon
      alt="icon"
      [ngStyle]="erroralertErroriconColor"
      class="alert-error-icon"
      svgIcon="alert-error-icon"
    ></mat-icon>
    <div class="alert-msg-info">
      <div [ngStyle]="erroralertHeaderColor" class="alert-header">
        {{ headerMsg }}
      </div>

      <div class="alert-body">
        <span [ngStyle]="erroralertHeaderColor"> {{ msg }} </span>
      </div>
    </div>
    @if(isShowCloseIcon){
    <span class="close-icon">
      <mat-icon [ngStyle]="errorcloseIconColor" (click)="close()" alt="icon" svgIcon="close-icon"></mat-icon>
    </span>
    }
  </div>
</div>
