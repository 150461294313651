import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import {
  Locale,
  SupportedDevice,
  ThemeConfig,
  registerCustomIcons,
} from '@finxone-platform/shared/sys-config-types';
import { Select, Store } from '@ngxs/store';
import { KeycloakService } from 'keycloak-angular';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { ConfigService } from './lib/services/config-service/config-service.service';
import { CountriesCurrenciesService } from './lib/services/countries-currencies-service/countries-currencies.service';
import { DeviceTypeService } from './lib/services/device-type/device-type.service';
import { IdleTimeoutService } from './lib/services/idle-timeout-service/idle-timeout.service';
import { ProgressBarState } from './lib/state/progress-bar.state';
import { ProjectSettingsState } from './lib/state/project-settings.state';
import { logLoadEventToFlutter } from './lib/utils/flutter.utils';
import { registerHandlerbarHelpers } from './lib/utils/template/handlebar-helpers/handlerbar-helper-register.utils';
import { setupNewThemeing } from './lib/utils/themeing.utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();
  title = 'finxone-web-frontend';
  secondaryFontColour = '';
  fontFamily = '';
  fontFamilyUrl = '';
  thirdColour = '';
  secondaryFontFamily = '';
  deviceInfo: DeviceInfo;

  @Select(ProgressBarState.getProgressBarStack)
  progressBarStack: Observable<{ uniqueId?: string | number }[]>;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private configService: ConfigService,
    private deviceDetectorService: DeviceDetectorService,
    private deviceTypeService: DeviceTypeService,
    protected keycloakService: KeycloakService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private idleTimeoutService: IdleTimeoutService,
    private countriesCurrenciesService: CountriesCurrenciesService,
    private store: Store,
  ) {}
  ngOnInit() {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.deviceTypeService.deviceType = this.deviceInfo.deviceType as SupportedDevice;

    registerHandlerbarHelpers(this.datePipe, this.currencyPipe, this.countriesCurrenciesService);
    this.configService
      .getTheme()
      .pipe(
        takeUntil(this.destroy$),
        map((theme: ThemeConfig) => {
          setupNewThemeing(theme);
        }),
      )
      .subscribe();

    registerCustomIcons(this.matIconRegistry, this.sanitizer);
    const locale = this.store.selectSnapshot(ProjectSettingsState.getProjectSettings)?.locale ?? Locale.en_GB;
    this.configService.refreshAlerts(locale as Locale);

    document.addEventListener('touchstart', function () {}, true); // allow :active styles to work in Mobile Safari

    logLoadEventToFlutter('angular-started');
  }
}
