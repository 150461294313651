import { Component, OnInit } from '@angular/core';
import { AlertHandlerService, AlertTypes } from '@finxone-platform/shared/services';

@Component({
  selector: 'finxone-web-frontend-alert-handler',
  templateUrl: './alert-handler.component.html',
  styleUrls: ['./alert-handler.component.scss'],
})
export class AlertHandlerComponent implements OnInit {
  alertType: string | typeof AlertTypes = '';
  alertMsg: string | undefined;
  headerMsg: string | undefined = 'Error!';
  isShowCloseIcon = true;
  constructor(private alertHandlerService: AlertHandlerService) {}
  ngOnInit() {
    this.alertHandlerService.showAlert.subscribe((res) => {
      this.alertType = res.type;
      this.alertMsg = res.msg;
      this.headerMsg = res.headerMsg;
      this.isShowCloseIcon = res?.isShowCloseIcon ?? true;
    });
  }
}
