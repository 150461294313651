import { Injectable } from '@angular/core';
import { AlertDetails } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AddProgressBarStack } from '../actions/progress-bar.action';
import { UpdateProjectAlerts } from '../actions/project-alerts.action';

export interface ProjectAlertsStateModel {
  toasterAlerts: { [errorCode: string]: AlertDetails };
}

@State<ProjectAlertsStateModel>({
  name: 'alerts',
  defaults: {
    toasterAlerts: {} as { [errorCode: string]: AlertDetails },
  },
})
@Injectable()
export class ProjectAlertsState {
  constructor() {}
  @Selector()
  static getProjectAlerts(state: ProjectAlertsStateModel) {
    return state;
  }
  @Selector()
  static getProjectToasterAlertsFromState(state: ProjectAlertsStateModel) {
    return state.toasterAlerts;
  }

  @Action(UpdateProjectAlerts)
  updateProjectAlerts(ctx: StateContext<ProjectAlertsStateModel>, action: UpdateProjectAlerts) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'UpdateProjectAlerts' }));

    ctx.patchState({
      ...ctx.getState(),
      toasterAlerts: action.alertData.toasterAlerts,
    });
  }
}
