import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { AlertDetails } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { KeycloakService } from 'keycloak-angular';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { ProjectAlertsState } from './state/project-alerts.state';
import { clearLocalStorageExcept } from './utils/utils';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private alertHandlerService: AlertHandlerService,
    protected keycloakService: KeycloakService,
    private store: Store,
  ) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          this.handleSuccessResponse(event);
        }
      }),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  private handleSuccessResponse(successEvent: HttpResponse<unknown>) {
    if ((successEvent.body as any)?.alertCodes) {
      this.findAndShowAlertFromCode((successEvent.body as any).alertCodes);
    }
  }
  handleError(error: HttpErrorResponse) {
    let errorMessage: any;
    let errorObj: any;
    if (this.isJSONString(error.error)) {
      errorObj = JSON.parse(error.error);
    } else {
      errorObj = error.error;
    }
    if (errorObj.alertCodes?.length) {
      this.findAndShowAlertFromCode(errorObj.alertCodes);
    } else if (errorObj?.message?.length) {
      if (typeof errorObj.message == 'string') {
        errorMessage = errorObj.message;
      } else {
        errorMessage = errorObj.message[0];
      }
      this.show(errorMessage);
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${
        errorObj?.message ? errorObj.message : 'Server Error'
      }`;
      this.show(errorMessage);
    }

    if (error.status === 401) {
      this.sendToLogin();
    }
    return throwError(() => new Error(errorMessage));
  }

  private findAndShowAlertFromCode(alertCodes: string[]) {
    const alerts = this.store.selectSnapshot(ProjectAlertsState.getProjectToasterAlertsFromState);
    const alertToDisplay: any[] = [];
    Object.entries(alerts).forEach((alert) => {
      if (alertCodes.includes(alert[0])) {
        alertToDisplay.push(alert[1]);
      }
    });
    alertToDisplay.forEach((alert) => {
      this.showAlertFromCode(alert);
    });
  }

  showAlertFromCode(alertData: AlertDetails) {
    this.alertHandlerService.showAlertFn(
      alertData.type.toLowerCase(),
      alertData.description,
      alertData.title,
    );
  }

  show(errorMessage: any) {
    this.alertHandlerService.showAlertFn('error', errorMessage);
  }

  isJSONString(str: string) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  private sendToLogin() {
    clearLocalStorageExcept([
      'account',
      'transactions',
      'beneficiary',
      'notifications',
      'lastLoggedInId',
      'APP_PREFERENCES',
      'firstTime',
    ]);
    this.keycloakService.login({
      redirectUri: window.location.href,
    });
  }
}
