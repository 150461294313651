import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum AlertTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

@Injectable({
  providedIn: 'root',
})
export class AlertHandlerService {
  showAlert = new Subject<{
    type: string | typeof AlertTypes;
    msg: string | undefined;
    headerMsg: string | undefined;
    isShowCloseIcon: boolean | undefined;
  }>();
  timer = 4000;
  showAlertFn(
    event: string | typeof AlertTypes,
    msg?: string,
    headerMsg?: string,
    timer?: number,
    isShowCloseIcon?: boolean,
  ) {
    this.showAlert.next({
      type: event,
      msg: msg,
      headerMsg: headerMsg,
      isShowCloseIcon: isShowCloseIcon,
    });
    setTimeout(() => {
      this.resetAlert();
    }, timer ?? this.timer);
  }

  resetAlert() {
    this.showAlert.next({
      type: '',
      msg: '',
      headerMsg: '',
      isShowCloseIcon: true,
    });
  }
}
