import { Injectable } from '@angular/core';
import { Observable } from '@apollo/client/utilities';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import {
  RevenirTransactionItemType,
  RevenirTransactionType,
  RevenirTripType,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import moment from 'moment';
import { catchError, tap } from 'rxjs';
import { SetFormActionWithId } from '../actions/form-submission.action';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import {
  GetRevenirTransactionDetails,
  GetRevenirTransactionItems,
  GetRevenirTransactionList,
  GetRevenirTransactionReceipt,
  GetRevenirTripList,
  setRevenirTrip,
} from '../actions/revenir.action';
import { RevenirService } from '../services/revenir-service/revenir.service';
import { ApiActions, UpdateApiIsLoadingAction } from './api-loading.state';

export interface RevenirStateModel {
  transactionList: RevenirTransactionType[];
  transactionDetails: RevenirTransactionType;
  transactionItems: RevenirTransactionItemType[];
  tripList: RevenirTripType[];
  selectedTripDetails: RevenirTripType | undefined;
  selectedTransactionDetails: RevenirTransactionType | undefined;
  selectedTransactionReceiptUrl: string;
}

@State<RevenirStateModel>({
  name: 'revenirState',
  defaults: {
    transactionList: [],
    transactionDetails: {} as RevenirTransactionType,
    transactionItems: [],
    tripList: [],
    selectedTripDetails: undefined,
    selectedTransactionDetails: undefined,
    selectedTransactionReceiptUrl: '',
  },
})
@Injectable()
export class RevenirState {
  constructor(private revenirService: RevenirService) {}

  @Selector()
  static getRevenirTransactionList(state: RevenirStateModel) {
    return state.transactionList;
  }

  @Selector()
  static getRevenirTransactionItemList(state: RevenirStateModel) {
    return state.transactionItems;
  }

  @Selector()
  static getRevenirStateDetails(state: RevenirStateModel) {
    return state;
  }

  @Selector()
  static getRevenirTripList(state: RevenirStateModel) {
    return state.tripList;
  }

  @Selector()
  static getSelectedRevenirTripDetails(state: RevenirStateModel) {
    return state.selectedTripDetails;
  }

  @Selector()
  static getSelectedRevenirTransactionReceiptUrl(state: RevenirStateModel) {
    return state.selectedTransactionReceiptUrl;
  }

  @Selector()
  static getSelectedRevenirTransactionDetails(state: RevenirStateModel) {
    return state.transactionDetails;
  }
  @Action(GetRevenirTransactionList)
  fetchRevenirTransactionList(ctx: StateContext<RevenirStateModel>, action: GetRevenirTransactionList) {
    const { getState, setState } = ctx;

    this.addLoaderInAPI(ctx, ApiActions.getRevenirTripTransactions, 'GetRevenirTransactionList');

    return this.revenirService.getTripDetails(action.tripId).pipe(
      tap((revenirTripDetails) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTripTransactions, 'GetRevenirTransactionList');
        setState({
          ...getState(),
          transactionList: revenirTripDetails?.transactions || [],
        });
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTripTransactions, 'GetRevenirTransactionList');
        throw _err;
      }),
    );
  }

  @Action(GetRevenirTripList)
  fetchRevenirTripList(ctx: StateContext<RevenirStateModel>) {
    const { getState, setState } = ctx;

    this.addLoaderInAPI(ctx, ApiActions.getRevenirTripList, 'GetRevenirTripList');

    return this.revenirService.getRevenirTrips().pipe(
      tap((revenirRevenirTripType) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTripList, 'GetRevenirTripList');
        setState({
          ...getState(),
          tripList: revenirRevenirTripType.trips || [],
        });
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTripList, 'GetRevenirTripList');
        throw _err;
      }),
    );
  }

  @Action(GetRevenirTransactionDetails)
  fetchRevenirTransactionDetails(ctx: StateContext<RevenirStateModel>, action: GetRevenirTransactionDetails) {
    const { getState, setState } = ctx;

    // resetting the transaction's details before calling API
    setState({
      ...getState(),
      transactionDetails: {} as RevenirTransactionType,
    });

    this.addLoaderInAPI(ctx, ApiActions.getRevenirTransactionDetails, 'GetRevenirTransactionDetails');

    return this.revenirService.getTransactionDetails(action.transactionId).pipe(
      tap((transactionDetails) => {
        this.removeLoaderFromAPI(
          ctx,
          ApiActions.getRevenirTransactionDetails,
          'GetRevenirTransactionDetails',
        );
        ctx.dispatch(new GetRevenirTransactionItems(action.transactionId));
        ctx.dispatch(new GetRevenirTransactionReceipt(action.transactionId));
        setState({
          ...getState(),
          transactionDetails: transactionDetails?.transaction,
        });

        ctx.dispatch(
          new SetFormActionWithId(
            {
              type: '',
              formData: {
                'total-amount': transactionDetails?.transaction?.total_amount,
                'total-vat-amount': transactionDetails?.transaction?.total_vat_amount,
                'merchant-name': transactionDetails?.transaction?.merchant_name,
                'transaction-date': moment(transactionDetails?.transaction?.transaction_date).format(
                  'DD-MM-YYYY',
                ),
              },
            },
            FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
          ),
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(
          ctx,
          ApiActions.getRevenirTransactionDetails,
          'GetRevenirTransactionDetails',
        );
        throw _err;
      }),
    );
  }

  @Action(GetRevenirTransactionItems)
  fetchRevenirTransactionItems(ctx: StateContext<RevenirStateModel>, action: GetRevenirTransactionItems) {
    const { getState, setState } = ctx;

    // resetting the transaction's items before calling API
    setState({
      ...getState(),
      transactionItems: [],
    });

    this.addLoaderInAPI(ctx, ApiActions.getRevenirTransactionItems, 'GetRevenirTransactionItems');

    return this.revenirService.getTransactionItems(action.transactionId).pipe(
      tap((transactionItems) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTransactionItems, 'GetRevenirTransactionItems');
        setState({
          ...getState(),
          transactionItems: transactionItems?.items || [],
        });
        ctx.dispatch(
          new SetFormActionWithId(
            {
              type: '',
              formData: { transactionItems: transactionItems?.items || [] },
            },
            FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
          ),
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(ctx, ApiActions.getRevenirTransactionItems, 'GetRevenirTransactionItems');
        throw _err;
      }),
    );
  }

  @Action(GetRevenirTransactionReceipt)
  fetchRevenirTransactionReceipt(ctx: StateContext<RevenirStateModel>, action: GetRevenirTransactionReceipt) {
    const { getState, setState } = ctx;

    // resetting the transaction's receipt URL before calling API
    setState({
      ...getState(),
      selectedTransactionReceiptUrl: '',
    });

    this.addLoaderInAPI(ctx, ApiActions.getRevenirTransactionReceipt, 'GetRevenirTransactionReceipt');

    return this.revenirService.getTransactionReceipt(action.transactionId).pipe(
      tap((transactionReceipt) => {
        const url = URL.createObjectURL(transactionReceipt as Blob);

        setState({
          ...getState(),
          selectedTransactionReceiptUrl: url || '',
        });

        this.removeLoaderFromAPI(
          ctx,
          ApiActions.getRevenirTransactionReceipt,
          'GetRevenirTransactionReceipt',
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.removeLoaderFromAPI(
          ctx,
          ApiActions.getRevenirTransactionReceipt,
          'GetRevenirTransactionReceipt',
        );
        throw _err;
      }),
    );
  }

  @Action(setRevenirTrip)
  setRevenirTrip(ctx: StateContext<RevenirStateModel>, action: setRevenirTrip) {
    ctx.patchState({
      ...ctx.getState(),
      selectedTripDetails: {
        ...ctx.getState()?.selectedTripDetails,
        ...action.selectedTripDetails,
      },
    });
  }

  addLoaderInAPI(ctx: StateContext<RevenirStateModel>, loadingType: ApiActions, stateAction: string) {
    ctx.dispatch(new UpdateApiIsLoadingAction(loadingType, true));
    ctx.dispatch(new AddProgressBarStack({ uniqueId: stateAction }));
  }

  removeLoaderFromAPI(ctx: StateContext<RevenirStateModel>, loadingType: ApiActions, stateAction: string) {
    ctx.dispatch(new UpdateApiIsLoadingAction(loadingType, false));
    ctx.dispatch(new RemoveProgressBarStack({ uniqueId: stateAction }));
  }
}
