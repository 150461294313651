<div class="alert-warning-wrapper" [ngStyle]="warningMsgdiv">
  <div class="alert-message">
    <mat-icon
      alt="icon"
      class="error-succes-icon"
      svgIcon="warning-icon"
      [ngStyle]="warningAlertErroriconColor"
    ></mat-icon>
    <div class="alert-msg-info">
      <div [ngStyle]="warningAlertHeaderColor" class="alert-header">
        {{ headerMsg }}
      </div>

      <div class="alert-body">
        <span [ngStyle]="warningAlertHeaderColor">{{ msg }} </span>
      </div>
    </div>
    @if(isShowCloseIcon){
    <span class="close-icon">
      <mat-icon
        [ngStyle]="warningCloseIconColor"
        (click)="close()"
        alt="icon"
        svgIcon="close-icon"
      ></mat-icon>
    </span>
    }
  </div>
</div>
