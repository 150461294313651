import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { verifyRoute } from '@app/finxone-web-frontend/app/app.module';
import { ProfileStateModel } from '@finxone-platform/shared/sys-config-types';
import { Actions, Select, Store } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { Observable, map, take } from 'rxjs';
import { GetProfile } from '../../actions/user-profile.action';
import { ProfileState } from '../../state/user-profile.state';
import { isAuthenticatedRoute } from '../../utils/auth-checks.utils';
import { ConfigService } from '../config-service/config-service.service';

@Injectable({
  providedIn: 'root',
})
export class InitializationService {
  @Select(ProfileState.getProfile)
  private profileStateData!: Observable<ProfileStateModel>;
  public activeRole: string;

  constructor(
    private store: Store,
    private router: Router,
    private configService: ConfigService,
    private actions: Actions,
  ) {}

  public initializeApp(token: string) {
    //check if data is already stored in state else it will dispatch get profile
    if (isAuthenticatedRoute(window.location.pathname)) {
      this.store.dispatch(new GetProfile());
      this.setRoleFromProfileState(token);
    } else {
      this.configService.setRoleInfo(token);
    }
  }
  setRoleFromProfileState(token: string) {
    this.profileStateData
      .pipe(
        take(2),
        map((profileData: ProfileStateModel) => {
          if (profileData?.id) {
            verifyRoute(this.router, profileData, false, this.store);
          }
          if (profileData.activeRole) {
            this.activeRole = profileData.activeRole;
            this.configService.setActiveRole(this.activeRole);
            if (profileData.activeOrganisationId) {
              // this.store.dispatch(
              //   new GetOrganisation(profileData.activeOrganisationId),
              // );
            }

            if (profileData.id && profileData.email) {
              Sentry.setUser({
                id: profileData.id,
                email: profileData.email,
              });
            }
          } else {
            // Atempt to try set the role
            this.configService.setRoleInfo(token);
          }
        }),
      )
      .subscribe();
  }
}
