import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  ComponentLabel,
  CustomQuestionRequest,
  OnboardingConfigFlowTypes,
  OrganisationOnboardingRequest,
  ProfileStateModel,
  SystemRoles,
  UserInfoType,
  WidgetNames,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { KeycloakService } from 'keycloak-angular';
import { Observable, Subject, debounceTime, first, map, take, takeUntil } from 'rxjs';
import {
  ResetFormDataAction,
  SetFormAction,
  UpdateFormDataAction,
  UpdateFormDataActionWithId,
} from '../../actions/form-submission.action';
import { AddProgressBarStack } from '../../actions/progress-bar.action';
import { SetRole } from '../../actions/role.action';
import { SetProfile } from '../../actions/user-profile.action';
import { ProjectSettingsState } from '../../state/project-settings.state';
import { ProfileState } from '../../state/user-profile.state';
import { ConfigService } from '../config-service/config-service.service';
import { MetadataService } from '../metadata-service/metadata-service.service';
import { OnboardIndividualRequest } from '../metadata-service/metadata-service.type';
import { MultiPartFormHolderService } from '../multipart-holder/multipart-form-holder.service';

export const formWidgets: WidgetNames[] = [
  WidgetNames.NAME_WIDGET,
  WidgetNames.EMAIL_WIDGET,
  WidgetNames.PHONE_WIDGET,
  WidgetNames.ADDRESS_WIDGET,
  WidgetNames.BIRTHDAY_WIDGET,
  WidgetNames.PASSWORD_WIDGET,
  WidgetNames.CUSTOM_QUESTION_WIDGET,
  WidgetNames.COMPANY_HOUSE_CHECK_WIDGET,
  WidgetNames.MOT_VERIFICATION_WIDGET,
];
@Injectable({
  providedIn: 'root',
})
export class FormSubmissionService {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();
  currentPageWidgets: ComponentLabel[] = [];
  currentPageFormGroups: FormGroup[] = [];
  systemRoles: SystemRoles;

  constructor(
    private store: Store,
    private metadataService: MetadataService,
    private configService: ConfigService,
    private _router: Router,
    private multiPartFormHolderService: MultiPartFormHolderService,
    protected keycloakService: KeycloakService,
    protected alertHandlerService: AlertHandlerService,
  ) {
    this.configService
      .getRoles()
      .pipe(
        first(),
        map((roles) => {
          this.systemRoles = roles;
        }),
      )
      .subscribe();
  }

  public get isWorkflow() {
    return this.configService.getActiveConfigType().isWorkflow;
  }

  private get onboardingConfig() {
    return this.store.selectSnapshot(ProjectSettingsState.getProjectSettings).onboardingFlow;
  }

  private getUserTypeByActiveRole(role: string): string {
    const activeRoleInfo = this.systemRoles[role];
    return activeRoleInfo.userType ?? '';
  }

  resetCurrentPageFormGroups() {
    this.currentPageFormGroups = [];
  }

  addCurrentPageFormGroups(formGroup: FormGroup) {
    const existingIndex = this.currentPageFormGroups.findIndex((group) => group === formGroup);
    if (existingIndex === -1) {
      this.currentPageFormGroups.push(formGroup);
    } else {
      this.currentPageFormGroups[existingIndex] = formGroup;
    }
  }

  updateCurrentPageFormGroups(index: number, updatedFormGroup: FormGroup) {
    this.currentPageFormGroups.splice(index, 1, updatedFormGroup);
  }

  currentPageIsValid(): boolean {
    return this.currentPageFormGroups.every((formGroup) => {
      if (formGroup.invalid) formGroup.markAllAsTouched(); // to show validation messages
      return formGroup.valid;
    });
  }

  async storeFormData(formData: Record<string, string>, id?: string): Promise<boolean> {
    try {
      if (id) {
        this.store.dispatch(new UpdateFormDataActionWithId(formData, id));
      } else {
        this.store.dispatch(new UpdateFormDataAction({ formData }));
      }

      return true;
    } catch (error) {
      console.error('Error storing form data:', error);
      return false;
    }
  }

  async submitIndividualForm(
    formData: Record<string, any>,
    nextPageUrl: string,
    id?: string,
  ): Promise<boolean> {
    try {
      const success = await this.storeFormData(formData, id);
      if (success && nextPageUrl?.length && this.checkWidgetValid()) {
        this._router.navigate([nextPageUrl]);
      }
      return success;
    } catch (error) {
      console.error('Error submitting form:', error);
      return false; // Return false in case of an error
    }
  }

  associateUserOrganisation(orgId: string): Observable<boolean> {
    this.store.dispatch(new AddProgressBarStack({ uniqueId: 'associateUserOrganisation' }));
    return this.metadataService.associateUserOrganisation(orgId);
  }

  checkWidgetValid(): boolean {
    let isWidgetValid = !this.currentPageWidgets.length;
    for (const widget of this.currentPageWidgets) {
      isWidgetValid = this.multiPartFormHolderService.config.stageIsValid(widget);
      if (isWidgetValid) {
        continue;
      } else {
        return isWidgetValid;
      }
    }
    return isWidgetValid;
  }

  addCurrentPageWidgets(widgets: ComponentLabel[]) {
    this.currentPageWidgets = widgets;
  }

  submitFormPayload(
    zone: string,
    formData: Record<string, string>,
    role: string,
    canvasSubscription: string,
    onboardingOrgId?: string,
    nextPageUrlFromInput?: string,
  ) {
    this.configService
      .getZoneByRoute()
      .pipe(take(1))
      .subscribe((zoneInfo) => {
        if (Object.keys(formData).length) {
          if (zoneInfo.zone === APP_ZONES.ORGANISATION_ONBOARDING) {
            this.onSubmitOrganisationOnboardingForm({
              formData,
              role,
              canvasSubscription,
            });
          } else {
            this.onSubmitUserOnboardingForm(
              {
                formData,
                role,
                canvasSubscription,
                onboardingOrgId,
              },
              nextPageUrlFromInput,
            );
          }
        } else {
          if (nextPageUrlFromInput === '/') {
            this.nextPageRoute('');
            return;
          }
          console.error('Error: Form data is not available');
        }
      });
  }

  onSubmitOrganisationOnboardingForm(payload: {
    formData: Record<string, string>;
    role: string;
    canvasSubscription: string;
  }): void {
    const userProfile = this.store.selectSnapshot(ProfileState.getProfile);
    const isUserLoggedIn = !!(userProfile?.id && localStorage.getItem('token'));
    const finalPayload = this.transformOrganisationFormDataIntoRequestPayload(
      payload.formData,
      payload.role,
      payload.canvasSubscription,
      this.customQuestionsRequest(payload.formData),
    );
    if (isUserLoggedIn) finalPayload['userId'] = userProfile?.id;
    this.metadataService
      .organisationOnboarding(finalPayload)
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$),
        map((orgId) => {
          if (orgId) {
            this.store.dispatch(new ResetFormDataAction());
            if (isUserLoggedIn) {
              this.alertHandlerService.showAlertFn('success', 'Organisation created successfully!');
              this.setUserRoleAndRedirect(userProfile, payload);
            } else {
              this.setOrgIdInFormStateAndRedirectUser(payload, orgId);
            }
          } else {
            return;
          }
        }),
      )
      .subscribe();
  }

  private setUserRoleAndRedirect(
    userProfile: ProfileStateModel,
    payload: {
      formData: Record<string, string>;
      role: string;
      canvasSubscription: string;
    },
  ) {
    this.store.dispatch(new SetRole(userProfile?.activeRole ?? payload.role));
    this.keycloakService.login({
      redirectUri: window.location.origin + '/post-auth',
    });
  }

  private setOrgIdInFormStateAndRedirectUser(
    payload: {
      formData: Record<string, string>;
      role: string;
      canvasSubscription: string;
    },
    orgId: string,
  ) {
    this.store.dispatch(
      new UpdateFormDataAction({
        formData: {
          orgName: payload.formData['name'],
          onboardingOrgId: orgId,
        },
      }),
    );
    this.redirectUserAsPerOnboardingFlow();
  }

  private redirectUserAsPerOnboardingFlow() {
    const onboardingFlow = this.store.selectSnapshot(ProjectSettingsState.getProjectSettings)?.onboardingFlow;
    if (onboardingFlow === OnboardingConfigFlowTypes.PERSONAL) {
      this._router.navigateByUrl('/');
    } else {
      this._router.navigateByUrl(`/zones/${APP_ZONES.ONBOARDING}/organisation-profile/owner/default`);
    }
  }

  onSubmitUserOnboardingForm(
    payload: {
      formData: Record<string, string>;
      role: string;
      canvasSubscription: string;
      onboardingOrgId?: string;
    },
    nextPageUrlFromInput: string = '',
  ) {
    const userType = this.getUserTypeByActiveRole(payload.role);
    this.metadataService
      .onboardIndividual(
        this.transformFormDataIntoRequestPayload(
          payload.formData,
          payload.role,
          payload.canvasSubscription,
          payload.onboardingOrgId,
        ),
      )
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$),
        map((response) => {
          if (response.data.id) {
            this.store.dispatch(new ResetFormDataAction());
            this.multiPartFormHolderService.resetFormStages();
            if (this.onboardingConfig === OnboardingConfigFlowTypes.PERSONAL && userType === 'org') {
              if (nextPageUrlFromInput.length && nextPageUrlFromInput !== '/') {
                this.nextPageRoute(nextPageUrlFromInput);
              } else {
                this.store.dispatch(
                  new SetFormAction({
                    type: '',
                    nextPageUrl: '',
                    isLastPage: false,
                    formData: { userId: response.data.id },
                  }),
                );
                this._router.navigateByUrl(
                  '/zones/organisation-onboarding/onboarding/organisation-anonymous/default',
                );
              }
            } else {
              this.nextPageRoute(nextPageUrlFromInput);
            }
          } else {
            this.store.dispatch(
              new SetFormAction({
                type: '',
                nextPageUrl: '',
                isLastPage: false,
              }),
            );
          }
        }),
      )
      .subscribe();
  }

  nextPageRoute(nextPageUrl: string = '') {
    if (nextPageUrl?.length) {
      this._router.navigateByUrl(nextPageUrl);
    } else {
      this.keycloakService.login({
        redirectUri: window.location.origin + '/feature-redirect',
      });
    }
  }

  transformFormDataIntoRequestPayload(
    formData: Record<string, string>,
    role: string,
    canvasSubscription: string,
    onboardingOrgId?: string,
  ): OnboardIndividualRequest {
    const additional = {
      company_name: formData?.['company_name'],
      company_number: formData?.['registration_number'],
      vehicle_registered_number: formData?.['registered_number'],
      role_name: role,
      subscription: canvasSubscription,
    };
    const request = {
      ...this.transformFormBaseFieldsData(formData),
      extraAttributes: additional,
      organisationId: onboardingOrgId,
    };
    return request as OnboardIndividualRequest;
  }

  transformFormBaseFieldsData(formData: Record<string, string>): OnboardIndividualRequest | UserInfoType {
    return {
      firstName: formData?.['first_name'],
      middleName: formData?.['middle_name'],
      lastName: formData?.['last_name'],
      dob: this.setDateFormat(formData?.['date_of_birth']).toString(),
      email: formData?.['email'],
      phoneNumber: formData?.['country_code'] + formData?.['phone'],
      password: formData?.['password'],
      country: formData?.['country'],
      address1: formData?.['line_1'],
      address2: formData?.['line_2'],
      address3: formData?.['line_3'],
      address4: formData?.['line_4'],
      city: formData?.['city'],
      county: formData?.['county'],
      postalCode: formData?.['post_code'],
      phoneVerified: formData?.['isPhoneVerified'],
      emailVerified: formData?.['isEmailVerified'],
    };
  }

  setDateFormat(date: string) {
    const newDate = new Date(date);
    const fullYear = newDate.getFullYear();
    const fullMonth = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    const fullDate = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();

    return fullYear + '-' + fullMonth + '-' + fullDate;
  }

  customQuestionsRequest(formData: Record<string, string>): CustomQuestionRequest[] {
    let customQuestions: CustomQuestionRequest[] = [];
    this.configService
      .getCurrentRoleZonePagesConfig()
      .pipe(take(1))
      .subscribe((pages) => {
        const pagesWidgetLayout = pages.map((page) => page.widgetLayouts);
        customQuestions = pagesWidgetLayout
          .flatMap((field) => field)
          .filter(
            (item) =>
              item.name === WidgetNames.CUSTOM_QUESTION_WIDGET &&
              // name is a key of organisation name so it is not a custom question for this request
              item.attributes['field_attributes'][0].name !== 'name',
          )
          .map((item) => {
            return {
              name: item.attributes['field_attributes'][0].name,
              type: item.attributes['field_attributes'][0].type,
              label:
                item.attributes['field_attributes'][0].label !== ''
                  ? item.attributes['field_attributes'][0].label
                  : item.attributes?.['label'],
              value: formData?.[item.attributes['field_attributes']?.[0]?.name],
            };
          });
      });

    return customQuestions;
  }

  transformOrganisationFormDataIntoRequestPayload(
    formData: Record<string, string>,
    role: string,
    canvasSubscription: string,
    customQuestions: CustomQuestionRequest[],
  ): OrganisationOnboardingRequest {
    const additional = {
      role_name: role,
      subscription: canvasSubscription,
      custom_questions: customQuestions,
    };
    let request: { [key: string]: any } = {
      name: formData?.['name'],
      country: formData?.['country'],
      address1: formData?.['line_1'],
      address2: formData?.['line_2'],
      address3: formData?.['line_3'],
      address4: formData?.['line_4'],
      city: formData?.['city'],
      state: formData?.['county'],
      postalCode: formData?.['post_code'],
      extraAttributes: additional,
      phoneVerified: formData?.['isPhoneVerified'],
      emailVerified: formData?.['isEmailVerified'],
      identificationNumber: formData?.['registration-number'],
      incorporationDate: formData?.['incorporation-date'],
      email: formData?.['email'],
    };
    if (formData?.['userId']) {
      request = {
        ...request,
        userId: formData?.['userId'],
      };
    }
    return request as OrganisationOnboardingRequest;
  }

  submitProfileUpdateRequest(formData: Record<string, string>) {
    const requestData = this.transformFormBaseFieldsData(formData);
    this.store.dispatch(new SetProfile(requestData as UserInfoType));
  }
}
