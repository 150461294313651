import { Component, Input } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AlertHandlerService } from '@finxone-platform/shared/services';

@Component({
  selector: 'finxone-platform-alert-warning-message',
  templateUrl: './alert-warning-message.component.html',
  styleUrls: ['./alert-warning-message.component.scss'],
})
export class AlertWarningMessageComponent {
  @Input() msg: string | undefined = 'Your Sample text here.';
  @Input() warningMsgdiv: {
    background: string;
    border: string;
    color: string;
    'border-width': string;
  };
  @Input() warningAlertErroriconColor: { color: string };
  @Input() warningAlertHeaderColor: { color: string; 'font-family': string };
  @Input() warningCloseIconColor: { color: string };
  @Input() headerMsg: undefined | string = 'Warning';
  @Input() isShowCloseIcon: boolean = true;
  constructor(private alertHandlerService: AlertHandlerService) {}
  close() {
    this.alertHandlerService.resetAlert();
  }
}
