import { RevenirTripType } from '@finxone-platform/shared/sys-config-types';

export class GetRevenirTransactionList {
  static readonly type = '[RevenirState] GetRevenirTransactionList';
  constructor(public tripId: string) {}
}
export class GetRevenirTripList {
  static readonly type = '[RevenirState] GetRevenirTripList';
}
export class setRevenirTrip {
  static readonly type = '[RevenirState] setRevenirTrip';
  constructor(public selectedTripDetails: RevenirTripType) {}
}

export class GetRevenirTransactionDetails {
  static readonly type = '[RevenirState] GetRevenirTransactionDetails';
  constructor(public transactionId: string) {}
}

export class GetRevenirTransactionItems {
  static readonly type = '[RevenirState] GetRevenirTransactionItems';
  constructor(public transactionId: string) {}
}

export class GetRevenirTransactionReceipt {
  static readonly type = '[RevenirState] GetRevenirTransactionReceipt';
  constructor(public transactionId: string) {}
}
