import { Locale } from '@finxone-platform/shared/sys-config-types';

export class GetProjectAlerts {
  static readonly type = '[Project] SetActiveProject';
  constructor(public params: { locale: Locale; key: string }) {}
}

export class UpdateProjectAlerts {
  static readonly type = '[Project] UpdateProjectAlerts';
  constructor(public alertData: any) {}
}
