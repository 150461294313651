<div class="alert">
  <finxone-platform-alert-success-message
    *ngIf="alertType === 'success'"
    [headerMsg]="headerMsg"
    [msg]="alertMsg"
    [isShowCloseIcon]="isShowCloseIcon"
  ></finxone-platform-alert-success-message>

  <finxone-platform-alert-error-message
    [msg]="alertMsg"
    [headerMsg]="headerMsg"
    *ngIf="alertType === 'error'"
    [isShowCloseIcon]="isShowCloseIcon"
  ></finxone-platform-alert-error-message>
  @if(alertType === 'warning'){
  <finxone-platform-alert-warning-message
    [msg]="alertMsg"
    [headerMsg]="headerMsg"
    [isShowCloseIcon]="isShowCloseIcon"
  ></finxone-platform-alert-warning-message
  >}
</div>
