import { Injectable } from '@angular/core';
import { Locale, OnboardingConfigFlowTypes } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UpdateProjectSettings } from '../actions/project-settings.action';

export interface ProjectSettingsStateModel {
  onboardingFlow: OnboardingConfigFlowTypes;
  defaultCurrency: string;
  checkPayeeEnabled: boolean;
  smsAuthenticationEnabled: boolean;
  enableBeneficiaryFirstAndLastName: boolean;
  locale: string;
  feeManagementEnabled: boolean;
  retryFailedFeeEnabled: boolean;
  feeReversalEnabled: boolean;
}

@State<ProjectSettingsStateModel>({
  name: 'projectSettings',
  defaults: {
    onboardingFlow: '' as OnboardingConfigFlowTypes,
    defaultCurrency: 'GBP',
    checkPayeeEnabled: true,
    smsAuthenticationEnabled: true,
    locale: Locale.en_GB,
    enableBeneficiaryFirstAndLastName: false,
    feeManagementEnabled: true,
    retryFailedFeeEnabled: true,
    feeReversalEnabled: true,
  },
})
@Injectable()
export class ProjectSettingsState {
  @Selector()
  static getProjectSettings(state: ProjectSettingsStateModel) {
    return state;
  }

  @Action(UpdateProjectSettings)
  updateProjectSettings(ctx: StateContext<ProjectSettingsStateModel>, action: UpdateProjectSettings) {
    ctx.patchState({
      onboardingFlow: action.payload.onboardingFlow,
      defaultCurrency: action.payload.defaultCurrency,
      checkPayeeEnabled: action.payload.checkPayeeEnabled,
      smsAuthenticationEnabled: action.payload.smsAuthenticationEnabled,
      locale: action.payload.locale,
      enableBeneficiaryFirstAndLastName: action.payload.enableBeneficiaryFirstAndLastName,
      feeManagementEnabled: action.payload.feeManagementEnabled,
      retryFailedFeeEnabled: action.payload.retryFailedFeeEnabled,
      feeReversalEnabled: action.payload.feeReversalEnabled,
    });
  }
}
