import { Injectable } from '@angular/core';
import { ACCOUNTTYPE } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  SetAddBeneficiaryFormState,
  UpdateAddBeneficiaryFormName,
} from '../actions/add-beneficiary-form.action';
import { AddBeneficiaryRequest } from '../services/account-service/account.type';

export interface AddBeneficiaryFormStateModel {
  item: AddBeneficiaryRequest;
}

@State<AddBeneficiaryFormStateModel>({
  name: 'addBeneficiaryState',
  defaults: {
    item: {
      name: 'Test User',
      accountType: ACCOUNTTYPE.PERSONAL,
      destinationIdentifier: {
        country: 'GB',
        currency: 'GBP',
        accountNumber: '12345678',
        sortCode: '12-34-56',
      },
    },
  },
})
@Injectable()
export class AddBeneficiaryFormState {
  @Selector()
  static getAddBeneficiaryForm(state: AddBeneficiaryFormStateModel) {
    return state;
  }

  @Action(SetAddBeneficiaryFormState)
  setAddBeneficiaryFormState(
    ctx: StateContext<AddBeneficiaryFormStateModel>,
    action: SetAddBeneficiaryFormState,
  ) {
    ctx.setState({
      item: action.payload,
    });
  }

  @Action(UpdateAddBeneficiaryFormName)
  updateAddBeneficiaryFormName(
    ctx: StateContext<AddBeneficiaryFormStateModel>,
    action: UpdateAddBeneficiaryFormName,
  ) {
    ctx.patchState({
      item: { ...ctx.getState().item, name: action.payload },
    });
  }
}
