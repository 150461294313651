import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RevenirTransactionItemType,
  RevenirTransactionType,
  TripDetailsType,
  TripsList,
} from '@finxone-platform/shared/sys-config-types';
import { Observable } from 'rxjs';
import { FormSubmissionType } from '../../state/form-submision.state';
import { createRevenirTripPayload } from '../../utils/revenir-utils';
import { ConfigService } from '../config-service/config-service.service';

@Injectable({
  providedIn: 'root',
})
export class RevenirService {
  private baseUrl: string;
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.configService.getApi('account_service').subscribe((response) => {
      this.baseUrl = response;
    });
  }

  public getTripTransactions(): Observable<RevenirTransactionType[]> {
    return this.http.get<RevenirTransactionType[]>(`${this.baseUrl}/revenir/transactions`);
  }

  public getTransactionDetails(transactionId: string): Observable<{ transaction: RevenirTransactionType }> {
    return this.http.get<{ transaction: RevenirTransactionType }>(
      `${this.baseUrl}/revenir/transactions/${transactionId}`,
    );
  }

  public getTransactionItems(transactionId: string): Observable<{ items: RevenirTransactionItemType[] }> {
    return this.http.get<{ items: RevenirTransactionItemType[] }>(
      `${this.baseUrl}/revenir/transactions/${transactionId}/items`,
    );
  }

  public getTransactionReceipt(transactionId: string): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(`${this.baseUrl}/revenir/transactions/${transactionId}/receipt`, httpOptions);
  }

  public getTripDetails(tripId: string): Observable<TripDetailsType> {
    return this.http.get<TripDetailsType>(`${this.baseUrl}/revenir/trips/${tripId}`);
  }

  public getRevenirTrips(): Observable<TripsList> {
    return this.http.get<TripsList>(`${this.baseUrl}/revenir/trips`);
  }
  public createRevenirTrip(payload: FormSubmissionType | null) {
    const newPayload = createRevenirTripPayload(payload?.formData);
    return this.http.post(`${this.baseUrl}/revenir/trips`, newPayload);
  }

  uploadReceipt(transactionId: string, file: File) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const binaryData = reader.result as ArrayBuffer;
      this.uploadBinaryFile(binaryData, file.name, transactionId);
    };
  }
  uploadBinaryFile(binaryData: ArrayBuffer, fileName: string, transactionId: string) {
    const blob = new Blob([binaryData], { type: 'application/octet-stream' });
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `attachment; filename="${fileName}"`,
      Accept: '*/*',
    });
    this.http
      .post(this.baseUrl + `/revenir/transactions/${transactionId}/receipts`, blob, { headers })
      .subscribe((response) => {
        console.log('Upload successful', response);
      });
  }
}
