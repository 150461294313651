import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { APP_ZONES, BaseWidgetProperties, ButtonActionType } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { switchMap } from 'rxjs';
import { ClearFormDataActionWithId } from '../../../actions/form-submission.action';
import { CtaButtonSignalService } from '../../../services/cta-button-signal-service/cta-button-signal.service';
import { RevenirService } from '../../../services/revenir-service/revenir.service';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function addRevenirTrip(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
  revenirService: RevenirService,
  ctaButtonSignalService: CtaButtonSignalService,
) {
  ctaButtonSignalService.setSignal({
    [ButtonActionType.ADD_REVENIR_NEW_TRIP]: false,
  });
  const data = store.select(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE),
  );
  data
    .pipe(
      switchMap((res) => {
        return revenirService.createRevenirTrip(res);
      }),
    )
    .subscribe((result) => {
      widgetProperties['urlToNavigate'] = 'trips-home-page';
      widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
      store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE));
      redirectToPage(router, widgetProperties);
    });
}
